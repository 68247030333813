export default {
  inject: ['repo'],

  props: {
    TeamRepo: {
      default() {
        return this.repo.get('teams');
      },
    },
  },
};
