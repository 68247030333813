import orderBy from 'lodash/orderBy';
import { get } from 'vuex-pathify';
import { League } from '@/models/orm/Hierarchy';
import HasTeams from '@/components/mixins/HasTeams';

export default {
  mixins: [HasTeams],

  data() {
    return {
      menuProps: {
        offsetY: true,
      },
    };
  },

  computed: {
    user: get('currentProfile'),

    leagues() {
      const localLeague = League;
      const sport = process.env.VUE_APP_SPORT_TYPE;

      const leagues = localLeague
        .query()
        .has('seasons', 1)
        .where('sport', (value) => (value.toLowerCase().search(sport.toLowerCase()) !== -1))
        .with('organization|seasons')
        .get();

      return leagues;
    },

    seasonsMapped() {
      return this.leagues.reduce((prev, cur, i) => {
        if (Array.isArray(prev)) {
          prev.push({ header: cur.displayName });
          prev = [...prev, ...orderBy(cur.seasons, ['startDate'], ['desc'])];
        }
        return prev;
      }, []);
    },
  },
};
