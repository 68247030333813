
<script>
import { sync } from 'vuex-pathify';
import { User } from '@/models/orm/Hierarchy';
import SeasonSelect from './SeasonSelect.mixin';

export default {
  name: 'CoreSeasonSelect',

  mixins: [SeasonSelect],

  computed: {
    season: sync('app/filters.season'),
  },

  methods: {
    async watchSeason(val, oldVal) {
      this.$log.debug(val, oldVal);
      if (val && val.hasOwnProperty('id')) {
        const resp = await this.TeamRepo.getManaged(val.id);

        User.update({
          where: this.user.ID,
          data: resp,
        });
      }
    },

    watchAvailableSeasons(val, oldVal) {
      if (val?.length && (!this.season?.id || !val.some(sn => sn?.id === this.season.id))) {
        this.season = this.seasonsMapped.find(rec => rec?.id);
      }
    },
  },

  watch: {
    season: {
      handler: 'watchSeason',
      immediate: true,
    },

    seasonsMapped: {
      handler: 'watchAvailableSeasons',
      immediate: true,
    },
  },
};
</script>

<template>
  <v-select
    v-model="season"
    :items="seasonsMapped"
    :menu-props="menuProps"
    item-value="id"
    item-text="displayName"
    return-object
    dense
    hide-details
    outlined
    v-bind="$attrs"
  ></v-select>
</template>
