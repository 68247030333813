<script>
import { AUTH_LOGOUT } from '@/store/actions/auth';
import Menuable from '../mixins/Menuable';

export default {
  name: 'CoreHeader',
  mixins: [Menuable],
  data() {
    return {
      companyLogo: '/img/logo/fs.svg',
      partnerLogo: process.env.VUE_APP_PARTNER_LOGO,
      height: 60,
    };
  },
  mounted() {
    this.$log.debug('CoreHeader list mounted.');
  },

  computed: {
    selectsDisabled() {
      const allowedRouteNames = ['players', 'player', 'sessions', 'session'];
      if (Array.isArray(this.$route.matched)) {
        return !allowedRouteNames.includes(this.$route.matched[0].name);
      }
      return !allowedRouteNames.includes(this.$route.name);
    },
  },

  methods: {
    async onLogout() {
      this.loading = true;
      await this.$store.dispatch(AUTH_LOGOUT);
    }
  },
};
</script>

<template>
<v-app-bar app dark class="coreHeader" :height="height">
  <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

  <v-toolbar-title class="mr-2 mr-lg-10 flex-grow-0">
    <router-link :disabled="loading" :to="{ name: 'players' }" class="d-block">
      <img :src="companyLogo" class="brand mr-2" />
      <img v-if="partnerLogo" :src="partnerLogo" class="brand brand--partner mr-2" />
    </router-link>
  </v-toolbar-title>

  <v-tabs
    v-if="$vuetify.breakpoint.mdAndUp && !justLogout"
    optional
    background-color="transparent"
    show-arrows
    class="tabs"
  >
    <v-tab
      v-for="({ to, label, disabled }, i) in links" :key="i"
      :disabled="loading || (isFunction(disabled) ? disabled() : false)"
      :to="to"
      exact
    >{{ label }}</v-tab>
  </v-tabs>

  <v-spacer v-if="justLogout"></v-spacer>

  <v-toolbar-items v-if="!justLogout">
    <core-season-select
      :disabled="loading || selectsDisabled"
      color="white"
      class="mt-3 navbar-select"
    ></core-season-select>

    <v-select
      v-if="$vuetify.breakpoint.mdAndUp"
      :disabled="loading || selectsDisabled"
      :value="selectedUnitsSystem"
      :items="unitSystems"
      :menu-props="menuProps"
      item-text="label"
      item-value="abbr"
      color="white"
      class="ml-3 mt-3 navbar-select"
      return-object
      dense
      hide-details
      outlined
      @input="updateMetrics"
    >
    </v-select>
  </v-toolbar-items>

  <v-divider vertical class="mx-3 mx-lg-4"></v-divider>

  <v-img
      v-if="currentProfile && currentProfile.Avatar && $vuetify.breakpoint.lgAndUp"
    :src="currentProfile.Avatar"
    height="40"
    width="40"
    color="transparent"
    class="circural mr-3 flex-grow-0"
  />
  <v-sheet
      v-if="$vuetify.breakpoint.mdAndUp && currentProfile && currentProfile.DisplayName"
      tile
      color="transparent"
      min-width="0"
      class="profile-wrapper d-flex flex-column justify-center"
    >
    <component :is="!loading && profileLink ? 'router-link' : 'span'" :to="profileLink" class="account-name-line text--nowrap">
      {{ currentProfile.DisplayName }}
    </component>
    <div class="account-line text--nowrap">
      <span
        v-if="hasSubscribeModule && $can('edit','Subscription') && $can('edit', 'User')"
      >{{ currentSubscription }}</span>
      <span v-else-if="currentProfile.isCustom">{{ $vuetify.lang.t('$vuetify.account.custom_type') }}</span>
      <span v-else>{{ $vuetify.lang.t('$vuetify.account.logged_in') }}</span>
    </div>
  </v-sheet>

  <v-btn icon :disabled="loading" :loading="loading" @click="onLogout">
    <!-- TODO: make it setable via props -->
    <v-icon >$vuetify.icons.logout</v-icon>
  </v-btn>
</v-app-bar>
</template>

<style lang="scss">
.text--nowrap {
  white-space: nowrap;
}
.coreHeader {
  background-color: var(--v-header_bg-base) !important;
  border-bottom: 1px solid;

  .brand {
    min-width: 40px;
    height: 40px !important;
    width: auto;
  }

  .v-tabs-slider {
    background-color: var(--v-anchor-base);
  }

  .v-tab:not(.v-tab--active) {
    color: inherit !important;
  }

  .account-line {
    font-size: 12px;
    line-height: 14px;
    color: #bcb9ba;
    text-decoration: none;
    margin-bottom: 3px;
  }

  .account-name-line {
    font-size: 17px;
    line-height: 19px;
    color: #e5e5ea;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tabs {
    @media #{map-get($display-breakpoints, 'md-only')} {
      max-width: 50%;
    }
}

.v-toolbar__content {
  padding-left: $grid-gutter / 2 !important;
  padding-right: $grid-gutter / 2 !important;
}

.profile-wrapper {
  flex-grow: 2;
  flex-basis: content;
  max-width: fit-content;
}
.v-tabs {
  flex: 0 1 content;
  margin-right: auto;
}
.navbar-select{
  max-width: 200px;
}

</style>
